import React, { useEffect, useState } from "react";
import { Image, Modal, Button, Progress, notification, Input } from "antd";
import Card from "components/card";
import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";

const General = () => {
  const [images, setImages] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imageDescription, setImageDescription] = useState("");
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(null);

  const axiosInstance = axios.create({
    baseURL: "https://api-ppmtqni.mku-dev.xyz/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/gallery/all");
      setImages(response.data.data); // Menyimpan data gambar dari response ke state images
    } catch (error) {
      console.error("Error fetching images:", error);
      // Handle error fetching images
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run effect only once on component mount

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCancel = () => {
    setModalAdd(false);
    setSelectedFile(null);
    setUploadProgress(0);
    setUploading(false);
    setImageDescription("");
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      notification.error({
        message: "Error",
        description: "Please select a file to upload.",
      });
      return;
    }

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("description", imageDescription); // Menambahkan deskripsi ke FormData

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      };

      await axiosInstance.post("/gallery/addImage", formData, config);

      notification.success({
        message: "Success",
        description: "Image uploaded successfully.",
      });

      fetchData(); // Ambil ulang data setelah berhasil mengunggah gambar
    } catch (error) {
      console.error("Error uploading image:", error);
      notification.error({
        message: "Error",
        description: "Failed to upload image. Please try again later.",
      });
    } finally {
      setUploading(false);
      setSelectedFile(null);
      setUploadProgress(0);
      setModalAdd(false);
      setImageDescription("");
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/gallery/${deleteImageId}`);
      notification.success({
        message: "Success",
        description: "Image deleted successfully.",
      });
      setConfirmDeleteVisible(false);
      fetchData(); // Ambil ulang data setelah berhasil menghapus gambar
    } catch (error) {
      console.error("Error deleting image:", error);
      notification.error({
        message: "Error",
        description: "Failed to delete image. Please try again later.",
      });
    }
  };

  const showDeleteConfirm = (imageId) => {
    setDeleteImageId(imageId);
    setConfirmDeleteVisible(true);
  };

  const handleCancelDelete = () => {
    setConfirmDeleteVisible(false);
  };

  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <div className="flex items-center justify-between ">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Galeri
          </h4>

          <Button
            type="primary"
            onClick={() => setModalAdd(true)}
            className="linear flex items-center justify-center rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-900 active:bg-brand-900 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Tambah
          </Button>
        </div>

        <p className="mt-2 px-2 text-base text-gray-600">
          Daftar images galeri yang terpasang di website PPMTQNI Brebes, silahkan melakukan aksi untuk data ini (melihat, menghapus, dan menambahkan).
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-3 gap-4 px-2">
        {images.map((image) => (
          <div
            key={image.id}
            className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none"
            style={{ height: '100px' }} // Ganti '150px' dengan tinggi yang Anda inginkan
          >
            <div
              className="text-red-500 text-md w-100 flex my-2 cursor-pointer"
              title="Hapus Gambar"
              onClick={() => showDeleteConfirm(image.id)}
            >
              <FaTrash/>
            </div>
            <Image src={image.img_url} style={{ height: '70px' }} /> {/* Menambahkan style height: '100%' untuk memenuhi kontainer */}
          </div>
        ))}
      </div>

      {/* Modal untuk konfirmasi delete */}
      <Modal
        title="Konfirmasi Hapus Gambar"
        open={confirmDeleteVisible}
        onCancel={handleCancelDelete}
        footer={[
          <Button key="cancel" onClick={handleCancelDelete}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" className="bg-brand-600" onClick={handleDelete}>
            Delete
          </Button>,
        ]}
      >
        <p>Anda yakin ingin menghapus gambar ini?</p>
      </Modal>

      {/* Modal untuk upload gambar */}
      <Modal
        title="Upload Foto Galeri"
        open={modalAdd}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={uploading}
            onClick={handleSubmit}
            className="bg-brand-600 "
          >
            Submit
          </Button>,
        ]}
      >
        <div className="max-w-xl mx-auto">
          <label
            className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
            id="drop"
          >
            <span className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              <span className="font-medium text-gray-600">
                Upload Foto Galeri{" "}
                <span className="text-blue-600 underline ml-1">from device</span>
              </span>
            </span>
            <input
              type="file"
              name="image"
              className="hidden"
              accept="image/png,image/jpeg"
              onChange={handleFileSelect}
            />
          </label>
          {selectedFile && (
            <p className="mt-2 text-sm text-gray-600">
              Selected file: {selectedFile.name}
            </p>
          )}

          {/* Input Deskripsi */}
          <Input
            className="mt-4"
            placeholder="Deskripsi gambar"
            value={imageDescription}
            onChange={(e) => setImageDescription(e.target.value)}
          />

          {uploading && (
            <div className="mt-4 mb-4">
              <Progress
                percent={uploadProgress}
                status={uploadProgress === 100 ? "success" : "active"}
              />
            </div>
          )}
          <label className="text-gray-700 text-xs mt-2">(Selalu isikan deskripsi setiap mengupload foto galeri min 3 karakter)</label>
        </div>
      </Modal>
    </Card>
  );
};

export default General;
