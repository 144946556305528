import React, { useEffect, useState } from 'react';
import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome, IoMdTrophy } from "react-icons/io";
import { IoAccessibility, IoAccessibilityOutline, IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard, MdPeople } from "react-icons/md";
import axios from 'axios';


import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import { Spin } from 'antd';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    totalTeachers: 0,
    totalBuku: 0,
    totalStudents: 0,
    totalBerita: 0,
    totalAgenda: 0,
    totalPrestasi: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [teachersResponse, bukuResponse, studentsResponse, beritaResponse, agendaResponse, prestasiResponse] =
          await Promise.all([
            axios.get('https://api-ppmtqni.mku-dev.xyz/api/teacher/all'),
            axios.get('https://api-ppmtqni.mku-dev.xyz/api/ebook/all'),
            axios.get('https://api-ppmtqni.mku-dev.xyz/api/ppdb/all', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }),
            axios.get('https://api-ppmtqni.mku-dev.xyz/api/information/latest'),
            axios.get('https://api-ppmtqni.mku-dev.xyz/api/information/upcoming'),
            axios.get('https://api-ppmtqni.mku-dev.xyz/api/achievement'),
          ]);

        setData({
          totalTeachers: teachersResponse.data.data.length,
          totalBuku: bukuResponse.data.data.length,
          totalStudents: studentsResponse.data.data.length,
          totalBerita: beritaResponse.data.data.length,
          totalAgenda: agendaResponse.data.data.length,
          totalPrestasi: prestasiResponse.data.data.length,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Data Daftar Online"}
          subtitle={loading ? <Spin/> : `${data.totalStudents} Santri`}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Data Buku E-Library"}
          subtitle={loading ? <Spin/> : `${data.totalBuku} Buku Tersedia`}

        />
        <Widget
          icon={<MdPeople className="h-7 w-7" />}
          title={"Total Data Tenaga Pendidik"}
          subtitle={loading ? <Spin/> : `${data.totalTeachers} Tenaga Pendidik`}
        />
      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
      <Widget
      icon={<IoAccessibility className="h-7 w-7" />}
      title={"Total Data Berita Terkini"}
      subtitle={loading ? <Spin /> : `${data.totalBerita} Berita`}
    />
        <Widget
          icon={<IoAccessibilityOutline className="h-6 w-6" />}
          title={"Total Data Agenda Mendatang"}
          subtitle={loading ? <Spin/> : `${data.totalAgenda} Agenda`}

        />
        <Widget
          icon={<IoMdTrophy className="h-7 w-7" />}
          title={"Total Data Prestasi"}
          subtitle={loading ? <Spin/> : `${data.totalPrestasi} Prestasi`}
        />
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2"> */}
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
