import React, { useState, useEffect } from "react";
import { MdModeEditOutline } from "react-icons/md";
import axios from 'axios';
import { Modal, message, Button, Image } from 'antd';
import Card from "components/card";
import { FaTrash } from "react-icons/fa";

const Notification = () => {
  const [buku, setBuku] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    book_url: ''
  });
  const [editFormData, setEditFormData] = useState({
    id: '',
    title: '',
    description: '',
    book_url: ''
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null); // State untuk menyimpan data target yang akan dihapus
  const [selectedBook, setSelectedBook] = useState(null); 

  const apiUrl = "https://api-ppmtqni.mku-dev.xyz/api/ebook";
  const token = localStorage.getItem('token'); // Token dari localStorage

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/all`);
      setBuku(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      message.error('Failed to fetch data');
    }
  };

  const openModal = (item) => {
    // Implement modal logic if needed
  };

  const openEditModal = (item) => {
    setEditFormData({
      id: item.id,
      title: item.title,
      description: item.description,
      book_url: item.book_url
    });
    setShowModalEdit(true);
  };

  const openDetailModal = (item) => {
    setSelectedBook(item);
    setShowModalDetail(true);
  };

  const confirmDelete = (item) => {
    setDeleteTarget(item);
    setDeleteModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await axios.delete(`${apiUrl}/${deleteTarget.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.success('Buku berhasil dihapus');
      setDeleteModalVisible(false);
      fetchData(); // Refresh data setelah penghapusan berhasil
    } catch (error) {
      console.error('Error deleting book:', error);
      message.error('Failed to delete book');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleEditInputChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formDataAPI = new FormData();
      formDataAPI.append('title', formData.title);
      formDataAPI.append('description', formData.description);
      formDataAPI.append('book_url', formData.book_url);
      formDataAPI.append('image', selectedFile);
      const response = await axios.post(`${apiUrl}`, formDataAPI, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.success('Buku berhasil ditambahkan');
      setShowModalAdd(false);
      fetchData();
    } catch (error) {
      console.error('Error adding book:', error);
      message.error('Failed to add book');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      const formDataAPI = new FormData();
      formDataAPI.append('title', editFormData.title);
      formDataAPI.append('description', editFormData.description);
      formDataAPI.append('book_url', editFormData.book_url);
      formDataAPI.append('image', selectedFile);
      const response = await axios.put(`${apiUrl}/${editFormData.id}`, formDataAPI, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.success('Buku berhasil diubah');
      setShowModalEdit(false);
      fetchData();
    } catch (error) {
      console.error('Error editing book:', error);
      message.error('Failed to edit book');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setShowModalAdd(false);
    setShowModalEdit(false);
    setDeleteModalVisible(false);
    setShowModalDetail(false); // Tutup modal detail
  };

  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            E-Library
          </h4>
          <Button
            type="primary"
            onClick={() => setShowModalAdd(true)}
            className="linear flex items-center justify-center rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-900 active:bg-brand-900 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Tambah
          </Button>
        </div>
      
        <p className="mt-2 text-base text-gray-600">
          Daftar buku digital yang terpasang di website PPMTQNI Brebes, silahkan melakukan aksi untuk data ini (melihat, mengedit, menghapus, dan menambahkan).
        </p>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        buku.map((item, index) => (
          <div key={index} className={`mt-${index === 0 ? '0' : '3'} flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:bg-navy-700 dark:shadow-none`}>
            <div className="flex items-center">
              <div className="">
                <img className="h-[83px] w-[83px] rounded-lg" src={item.cover_image} alt="" />
              </div>
              <div className="ml-4">
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {item.title}
                </p>
                <p className="mt-2 text-sm text-gray-600">
                  {item.description} .
                  <a
                    className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white cursor-pointer"
                    onClick={() => openDetailModal(item)} // Panggil fungsi openDetailModal saat diklik
                  >
                    See details
                  </a>
                </p>
              </div>
            </div>
            <div className="mr-4 flex items-center justify-center text-gray-600 gap-3 dark:text-white">
              <MdModeEditOutline
                onClick={() => openEditModal(item)}
                title="Edit Prestasi"
                className="cursor-pointer"
              />
              <div
                className="text-red-500 text-md w-100 flex my-2 cursor-pointer"
                title="Hapus Prestasi"
                onClick={() => confirmDelete(item)}
              >
                <FaTrash />
              </div>
            </div>
          </div>
        ))
      )}

      {/* Modal untuk tambah buku */}
      <Modal
        open={showModalAdd}
        title="Tambah Prestasi"
        onCancel={handleCancel}
        footer={null}
      >
        <div className='p-5'>
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label htmlFor="title" className="mb-3 block text-sm font-medium text-[#07074D]">
                Judul
              </label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Judul"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-sm font-medium text-[#07074D]">
                Deskripsi
              </label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder="Enter Deskripsi"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="location" className="mb-3 block text-sm font-medium text-[#07074D]">
                URL Buku
              </label>
              <input
                type="text"
                name="book_url"
                id="book_url"
                placeholder="Enter url"
                value={formData.book_url}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="relative w-full">
              <div className="items-center justify-center max-w-xl mx-auto">
                <label
                  className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                >
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Upload Foto Buku Cover
                      <span className="text-blue-600 underline ml-[4px]">from device</span>
                    </span>
                  </span>
                  <input
                    type="file"
                    name="image"
                    className="hidden"
                    accept="image/png,image/jpeg"
                    id="image"
                    onChange={handleFileSelect}
                  />
                </label>
                {selectedFile && (
                  <p className="mt-2 text-sm text-gray-600">
                    Selected file: {selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {loading ? 'Loading...' : 'Tambah Data'}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Modal untuk edit buku */}
      <Modal
        open={showModalEdit}
        title="Edit Prestasi"
        onCancel={handleCancel}
        footer={null}
      >
        <div className='p-5'>
          <form onSubmit={handleEdit}>
            <div className="mb-5">
              <label htmlFor="title" className="mb-3 block text-sm font-medium text-[#07074D]">
                Judul
              </label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Judul"
                value={editFormData.title}
                onChange={handleEditInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-sm font-medium text-[#07074D]">
                Deskripsi
              </label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder="Enter Deskripsi"
                value={editFormData.description}
                onChange={handleEditInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="location" className="mb-3 block text-sm font-medium text-[#07074D]">
                URL Buku
              </label>
              <input
                type="text"
                name="book_url"
                id="book_url"
                placeholder="Enter url"
                value={editFormData.book_url}
                onChange={handleEditInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="relative w-full">
              <div className="items-center justify-center max-w-xl mx-auto">
                <label
                  className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                >
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Upload Foto Buku Cover
                      <span className="text-blue-600 underline ml-[4px]">from device</span>
                    </span>
                  </span>
                  <input
                    type="file"
                    name="image"
                    className="hidden"
                    accept="image/png,image/jpeg"
                    id="image"
                    onChange={handleFileSelect}
                  />
                </label>
                {selectedFile && (
                  <p className="mt-2 text-sm text-gray-600">
                    Selected file: {selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {loading ? 'Loading...' : 'Simpan Perubahan'}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Modal untuk konfirmasi penghapusan */}
      <Modal
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
    
        title="Konfirmasi Penghapusan"
     
        footer={null}
      >
        <p>Apakah Anda yakin ingin menghapus buku ini?</p>

        <div className="mt-5">
              <button
                onClick={handleDelete}
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {loading ? 'Loading...' : 'Hapus'}
              </button>
            </div>
      </Modal>

      <Modal
        visible={showModalDetail}
        onCancel={handleCancel}
        title="Detail Buku"
        footer={null}
      >
        <div className="p-5">
          {selectedBook && (
            <div>
              <p className="font-bold">Judul: {selectedBook.title}</p>
              <p className="mt-2">Deskripsi: {selectedBook.description}</p>
              <p className="mt-2">URL Buku: <a href={selectedBook.book_url} target="_blank" rel="noopener noreferrer">{selectedBook.book_url}</a></p>
              <p className="my-2">Cover Buku </p>
              <Image
          src={selectedBook?.cover_image}
          alt={selectedBook?.title}
          preview={true}
          width={400}
        />
            </div>
          )}
        </div>
      </Modal>
     
    </Card>
  );
};

export default Notification;
