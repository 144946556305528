import React, { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { Modal, Button, Image, message, Spin } from 'antd'; // Mengimpor komponen Image dari Ant Design
import Card from "components/card";
import axios from 'axios';
import { format } from 'date-fns';
import { LoadingOutlined } from '@ant-design/icons';


const BeritaTerkini = () => {
  const [berita, setBerita] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [detailBerita, setDetailBerita] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    author: '',
    image: null, // ini akan menyimpan file gambar
  });

  const [loading, setLoading] = useState(false); // state untuk loading saat proses submit
  const [modalAddVisible, setModalAddVisible] = useState(false); // state untuk modal tambah berita
  const [successMessage, setSuccessMessage] = useState(''); // state untuk pesan sukses
  const [errorMessage, setErrorMessage] = useState(''); // state untuk pesan error
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false); // state untuk loading saat proses delete
  const [deleteModalVisible, setDeleteModalVisible] = useState(false); // state untuk modal konfirmasi delete
  const [deleteItemId, setDeleteItemId] = useState(null); // state untuk menyimpan ID item yang akan dihapus
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(''); // state untuk pesan sukses delete
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(''); // state untuk pesan error delete

  const [editModalVisible, setEditModalVisible] = useState(false); // state untuk modal edit berita
  const [editFormData, setEditFormData] = useState({
    id: null,
    title: '',
    description: '',
    author: '',
    image: null,
  });
  const [loadingEdit, setLoadingEdit] = useState(false); // state untuk loading saat proses edit
  const [editSuccessMessage, setEditSuccessMessage] = useState(''); // state untuk pesan sukses edit
  const [editErrorMessage, setEditErrorMessage] = useState(''); // state untuk pesan error edit
  

  const axiosInstance = axios.create({
    baseURL: "https://api-ppmtqni.mku-dev.xyz/api",
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/information/latest");
      setBerita(response.data.data);
    } catch (error) {
      console.error("Error fetching latest news:", error);
      // Handle error fetching latest news
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDetailClick = (item) => {
    setDetailBerita(item);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('token'); // ambil token dari local storage

    try {
      const formPayload = new FormData();
      formPayload.append('title', formData.title);
      formPayload.append('description', formData.description);
      formPayload.append('author', formData.author);
      formPayload.append('image', formData.image);

      const response = await axiosInstance.post('/information/latest', formPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setLoading(false);
      setSuccessMessage('Berita berhasil ditambahkan!');
      setModalAddVisible(false);
      fetchData(); // refresh data berita setelah berhasil tambah

      // Menampilkan pesan sukses menggunakan Ant Design message
      message.success('Berita berhasil ditambahkan!');
    } catch (error) {
      setLoading(false);
      setErrorMessage('Gagal menambahkan berita. Silakan coba lagi.');
      // Menampilkan pesan error menggunakan Ant Design message
      message.error('Gagal menambahkan berita. Silakan coba lagi.');
      console.error('Error adding news:', error);
    }
  };

  const showDeleteModal = (id) => {
    setDeleteItemId(id);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    setLoadingDelete(true);

    const token = localStorage.getItem('token'); // ambil token dari local storage
    const deleteUrl = `/information/latest/${deleteItemId}`;

    try {
      await axiosInstance.delete(deleteUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLoadingDelete(false);
      setDeleteSuccessMessage('Berita berhasil dihapus!');
      setDeleteModalVisible(false);
      fetchData(); // refresh data berita setelah berhasil hapus

      // Menampilkan pesan sukses menggunakan Ant Design message
      message.success('Berita berhasil dihapus!');
    } catch (error) {
      setLoadingDelete(false);
      setDeleteErrorMessage('Gagal menghapus berita. Silakan coba lagi.');
      // Menampilkan pesan error menggunakan Ant Design message
      message.error('Gagal menghapus berita. Silakan coba lagi.');
      console.error('Error deleting news:', error);
    }
  };


  const showEditModal = (item) => {
    setEditFormData({
      id: item.id,
      title: item.title,
      description: item.description,
      author: item.author,
      image: null, // Gambar tidak diambil kembali untuk alasan keamanan
    });
    setEditModalVisible(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
  
    const token = localStorage.getItem('token'); // ambil token dari local storage
    const editUrl = `/information/latest/${editFormData.id}`;
  
    try {
      const formPayload = new FormData();
      formPayload.append('title', editFormData.title);
      formPayload.append('description', editFormData.description);
      formPayload.append('author', editFormData.author);
      if (editFormData.image) {
        formPayload.append('image', editFormData.image);
      }
  
      const response = await axiosInstance.put(editUrl, formPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setLoadingEdit(false);
      setEditSuccessMessage('Berita berhasil diupdate!');
      setEditModalVisible(false);
      fetchData(); // refresh data berita setelah berhasil edit
  
      // Menampilkan pesan sukses menggunakan Ant Design message
      message.success('Berita berhasil diupdate!');
    } catch (error) {
      setLoadingEdit(false);
      setEditErrorMessage('Gagal mengupdate berita. Silakan coba lagi.');
      // Menampilkan pesan error menggunakan Ant Design message
      message.error('Gagal mengupdate berita. Silakan coba lagi.');
      console.error('Error updating news:', error);
    }
  };
  


  return (
    <div>
      {/* Header */}
      <div className="mb-4 mt-10 flex flex-col justify-between px-4 md:flex-row md:items-center">
        <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
          Berita Terkini
        </h4>
        <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
          <li>
            <label
              className="text-xs font-medium flex items-center text-white dark:text-white bg-brand-300 cursor-pointer p-3  rounded-full"
              onClick={() => setModalAddVisible(true)}
            >
              Tambah Berita
              <BsPlus style={{ fontSize: "20px" }} />
            </label>
          </li>
        </ul>
      </div>

      {/* Cards */}
      <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
        {berita.map((item) => (
          <Card
            key={item.id}
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white `}
          >
            <div className="h-full w-full">
              <div className="relative w-full">
                <img
                  src={item.image_url}
                  className="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
                  alt=""
                />
              </div>

              <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                <div className="mb-2">
                  <p className="text-lg font-bold text-navy-700 dark:text-white">
                    {item.title}
                  </p>
                  <p className="mt-1 text-sm font-medium text-gray-700 md:mt-2">
                     {item.author}
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                <div className="flex">
                  <p
                    className="mb-2 text-sm font-bold text-brand-500 dark:text-white underline cursor-pointer"
                    onClick={() => handleDetailClick(item)}
                  >
                    Lihat Detail
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <button
                    href=""
                    className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    onClick={() => showEditModal(item)}
                 >
                    Edit
                  </button>

                  <button
                    href=""
                    className="linear rounded-[20px] bg-red-700 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-800 active:bg-red-700 dark:bg-red-400 dark:hover:bg-red-300 dark:active:opacity-90"
                    onClick={() => showDeleteModal(item.id)}
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Modal */}
      <Modal
        title={detailBerita?.title}
        open={modalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Tutup
          </Button>
        ]}
      >
        <div className="text-center">
          <Image src={detailBerita?.image_url} alt={detailBerita?.title} /> {/* Menggunakan komponen Image dari Ant Design */}
          <p className="mt-2">{detailBerita?.description}</p>
          {/* <p className="mt-2">Tanggal: {format(new Date(detailBerita?.date), 'dd MMMM yyyy')}</p> */}
          <p className="mt-2">Waktu: {detailBerita?.author}</p>
        </div>
      </Modal>

      <Modal
        open={modalAddVisible}
        title="Tambah Berita"
        onCancel={() => setModalAddVisible(false)}
        footer={null}
      >
        <div className="p-5">
          <form onSubmit={handleSubmit}>
            {/* Form inputs */}
            {/* Judul */}
            <div className="mb-5">
              <label htmlFor="title" className="mb-3 block text-sm font-medium text-[#07074D]">
                Judul
              </label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Judul berita"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            {/* Deskripsi */}
            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-sm font-medium text-[#07074D]">
                Deskripsi
              </label>
              <textarea
                name="description"
                id="description"
                placeholder="Deskripsi berita"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            {/* Author */}
            <div className="mb-5">
              <label htmlFor="author" className="mb-3 block text-sm font-medium text-[#07074D]">
                Waktu
              </label>
              <input
                type="text"
                name="author"
                id="author"
                placeholder="Contoh : 12 Nov 2024"
                value={formData.author}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
              <div className="relative w-full">
                <div className="items-center justify-center max-w-xl mx-auto">
                  <label
                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                    id="drop"
                  >
                    <span className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <span className="font-medium text-gray-600">
                        Upload Foto Tenaga Pendidik
                        <span className="text-blue-600 underline ml-[4px]">from device</span>
                      </span>
                    </span>
                    <input
                      type="file"
                      name="image"
                      className="hidden"
                      accept="image/png,image/jpeg"
                      id="image"
                      onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                    />
                  </label>
                  {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                  {selectedFile && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {selectedFile.name}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Tombol submit */}
            <div>
              <button
                type="submit"
                className="w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {loading ? 'Loading...' : 'Tambah Berita'}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Modal Konfirmasi Delete */} 
      <Modal
        open={deleteModalVisible}
        title="Konfirmasi Hapus Berita"
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
            Batal
          </Button>,
          <Button
            key="delete"
            type="primary"
            className="rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
            danger
            onClick={handleDeleteConfirm}
            loading={loadingDelete}
          >
            {loadingDelete ? 'Menghapus...' : 'Hapus'}
          </Button>,
        ]}
      >
        <p>Apakah Anda yakin ingin menghapus berita ini?</p>
      </Modal>



      {/* Modal Edit */}
<Modal
  open={editModalVisible}
  title="Edit Berita"
  onCancel={() => setEditModalVisible(false)}
  footer={[]}
>
  <div className="p-5">
    <form onSubmit={handleEditSubmit}>
      {/* Form inputs */}
      {/* Judul */}
      <div className="mb-5">
        <label htmlFor="edit-title" className="mb-3 block text-sm font-medium text-[#07074D]">
          Judul
        </label>
        <input
          type="text"
          id="edit-title"
          placeholder="Judul berita"
          value={editFormData.title}
          onChange={(e) => setEditFormData({ ...editFormData, title: e.target.value })}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
      </div>

      {/* Deskripsi */}
      <div className="mb-5">
        <label htmlFor="edit-description" className="mb-3 block text-sm font-medium text-[#07074D]">
          Deskripsi
        </label>
        <textarea
          id="edit-description"
          placeholder="Deskripsi berita"
          value={editFormData.description}
          onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
      </div>

      {/* Author */}
      <div className="mb-5">
        <label htmlFor="edit-author" className="mb-3 block text-sm font-medium text-[#07074D]">
          Waktu
        </label>
        <input
          type="text"
          id="edit-author"
          placeholder="Contoh: 24 Nov 2024"
          value={editFormData.author}
          onChange={(e) => setEditFormData({ ...editFormData, author: e.target.value })}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
      </div>

     
      <div className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
              <div className="relative w-full">
                <div className="items-center justify-center max-w-xl mx-auto">
                  <label
                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                    id="drop"
                  >
                    <span className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <span className="font-medium text-gray-600">
                        Upload Foto Tenaga Pendidik
                        <span className="text-blue-600 underline ml-[4px]">from device</span>
                      </span>
                    </span>
                    <input
          type="file"
          id="edit-image"
          accept="image/png,image/jpeg"
          onChange={(e) => setEditFormData({ ...editFormData, image: e.target.files[0] })}
          className="w-full"
        />
                  </label>
                  {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                  {selectedFile && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {selectedFile.name}
                    </p>
                  )}
                </div>
              </div>
            </div>

      {/* Tombol submit */}
      <div>
        <button
          type="submit"
          className="w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
        >
          {loadingEdit ? 'Updating...' : 'Update Berita'}
        </button>
      </div>
    </form>
  </div>
</Modal>


    </div>
  );
};

export default BeritaTerkini;
