import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'antd';


export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false); // State untuk loading indicator
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Validasi sederhana di sisi klien
    if (!email || !password) {
      alert('Email and password are required.');
      return;
    }
  
    try {
      setLoading(true);
      // Send login request to backend
      const response = await axios.post('https://api-ppmtqni.mku-dev.xyz/api/auth/signin', {
        email,
        password
      });
      
      // Save token to localStorage upon successful login
      localStorage.setItem('token', response.data.token);
  
      // Navigate to dashboard upon successful login
      navigate('/admin');
    } catch (error) {
      // Handle request error
      console.error('Error during login:', error);
      alert('An error occurred during login. Please try again.');
    }finally {
      setLoading(false); // Set isLoading kembali ke false setelah selesai proses login, baik sukses maupun gagal
    }
  };
  

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Dashboard | PPMTQNI
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

  


<div className={`mb-3`}>
      <label
        htmlFor="email"
        className={`text-sm text-navy-700 dark:text-white`}
      >
        Email
      </label>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      type='email'
        id="email"
        placeholder="Masukan Email"
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white`}
      />
    </div>

      
      

<div className={`mb-3`}>
      <label
        htmlFor="password"
        className={`text-sm text-navy-700 dark:text-white`}
      >
        Password
      </label>
      <input
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      type='password'
        id="password"
        placeholder="Masukan Password"
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white`}
      />
    </div>

      
        {/* Sign In button */}
        <Button
          className="linear  mt-2 w-full rounded-xl bg-brand-500  text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          type="primary"
          loading={isLoading} // Menampilkan spinner saat isLoading true
          onClick={handleLogin}
          style={{height:"45px"}}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
}
