
import React, { useEffect, useRef, useState } from 'react';
import Card from "components/card";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Image, Input, Space, Table, Pagination, Spin } from 'antd';
import axios from 'axios';
import { BsEye } from 'react-icons/bs';



  
  

const PPDBSantri = (props) => {
    const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const token = localStorage.getItem('token'); // Ambil token dari local storage

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api-ppmtqni.mku-dev.xyz/api/ppdb/all', {
          headers: {
            Authorization: `Bearer ${token}` // Sertakan token dalam header Authorization
          }
        });
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    } else {
      setLoading(false);
      console.error('Token not found in local storage.');
    }
  }, [token]); // Token digunakan sebagai dependency sehingga efek ini dijalankan ulang saat token berubah


  const searchInput = useRef(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleChangePagination = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            className='bg-blue-800 text-white'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ fontWeight: 'bold' }}>
          {text}
        </span>
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Nama Lengkap',
      dataIndex: 'nama_lengkap',
      key: 'nama_lengkap',
      fixed: 'left',
      width:"150px",
      ...getColumnSearchProps('nama_lengkap'),
    },
    {
        title: 'Jenjang Pendidikan',
        dataIndex: 'jenjang_pendidikan',
        key: 'jenjang_pendidikan',
        width: "150px",
        fixed: 'left',
        render: (text) => (
            <label className={`p-1 rounded ${text.toLowerCase() === 'sma' ? 'bg-green-700 text-white' : 'bg-blue-700 text-white'}`}>
            {text.toUpperCase()}
          </label>          
        ),
      },
      
      
    {
      title: 'Asal Sekolah',
      dataIndex: 'asal_sekolah',
      key: 'asal_sekolah',
      width:"150px",
      ...getColumnSearchProps('asal_sekolah'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width:"200px",
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      width:"90px",
      ...getColumnSearchProps('gender'),
    },
    {
      title: 'TTL Santri',
      dataIndex: 'ttl_santri',
      key: 'ttl_santri',
      width:"200px",
    
    },
    {
      title: 'Alamat Santri',
      dataIndex: 'alamat_santri',
      key: 'alamat_santri',
      width:"300px",
      ...getColumnSearchProps('alamat_santri'),
    },
    {
      title: 'Pengalaman Al-Quran',
      dataIndex: 'pengalaman_alquran',
      key: 'pengalaman_alquran',
      ...getColumnSearchProps('pengalaman_alquran'),
      width:"180px",
    },
    {
      title: 'Peringkat',
      dataIndex: 'peringkat',
      key: 'peringkat',
      width:"150px",

    },
    {
      title: 'No Telepon',
      dataIndex: 'no_telepon',
      key: 'no_telepon',
      width:"150px",
     
    },
    {
      title: 'No WA',
      dataIndex: 'no_wa',
      key: 'no_wa',
      width:"150px",
    
    },
    {
      title: 'Nama Ayah',
      dataIndex: 'nama_ayah',
      key: 'nama_ayah',
      width:"150px",
     
    },
    {
      title: 'Nama Ibu',
      dataIndex: 'nama_ibu',
      key: 'nama_ibu',
      width:"150px",
  
    },
    {
      title: 'Pekerjaan Ayah',
      dataIndex: 'pekerjaan_ayah',
      key: 'pekerjaan_ayah',
      width:"150px",
    
    },
    {
      title: 'Pekerjaan Ibu',
      dataIndex: 'pekerjaan_ibu',
      key: 'pekerjaan_ibu',
      width:"150px",
     
    },
    {
      title: 'Alamat Ortu',
      dataIndex: 'alamat_ortu',
      key: 'alamat_ortu',
      width:"300px",

   
    },
    {
      title: 'Nama Wali',
      dataIndex: 'nama_wali',
      key: 'nama_wali',
      width:"180px",
     
    },
    {
      title: 'Alamat Wali',
      dataIndex: 'alamat_wali',
      key: 'alamat_wali',
      width:"200px",
    },
    {
      title: 'Asal Informasi',
      dataIndex: 'asal_informasi',
      key: 'asal_informasi',
      ...getColumnSearchProps('asal_informasi'),
      width:"150px",
    },
    {
      title: 'Pasfoto',
      dataIndex: 'pasfoto_url',
      key: 'pasfoto_url',
      render: (text) => <Image src={text} width={40} height={40}/>,
      width:"100px",

    },
    {
      title: 'Akta Kelahiran',
      dataIndex: 'akta_kelahiran',
      key: 'akta_kelahiran',
      render: (text) => <Image src={text} width={40} height={40}/>,

      width:"100px",

    },
    {
      title: 'Kartu Keluarga',
      dataIndex: 'kartu_keluarga',
      key: 'kartu_keluarga',
      render: (text) => <Image src={text} width={40} height={40}/>,

      width:"100px",

    },
    {
      title: 'KTP Ayah',
      dataIndex: 'ktp_ayah',
      key: 'ktp_ayah',
      render: (text) => <Image src={text} width={40} height={40}/>,

      width:"100px",

    },
    {
      title: 'KTP Ibu',
      dataIndex: 'ktp_ibu',
      key: 'ktp_ibu',
      render: (text) => <Image src={text} width={40} height={40}/>,

      width:"100px",

    },
    {
      title: 'Bukti Transfer',
      dataIndex: 'bukti_transfer',
      key: 'bukti_transfer',
      render: (text) => <Image src={text} width={40} height={40}/>,
      width:"100px",

    },
    {
      title: 'Rapor SD',
      dataIndex: 'rapor_sd',
      key: 'rapor_sd',
      render: (text) => <a href={text} target='blank' className='text-gray-600 flex items-center gap-1' title='See PDF'><BsEye/> Lihat PDF </a>,
      width:"120px",

    },
    {
      title: 'Rapor SMP',
      dataIndex: 'rapor_smp',
      key: 'rapor_smp',
      render: (text) => <a href={text} target='blank' className='text-gray-600 flex items-center gap-1' title='See PDF'><BsEye/> Lihat PDF </a>,
      width:"120px",
    },
    {
      title: 'Sertifikat Khatam',
      dataIndex: 'sertif_khatam',
      key: 'sertif_khatam',
      render: (text) => <a href={text} target='blank' className='text-gray-600 flex items-center gap-1' title='See PDF'><BsEye/> Lihat PDF </a>,
      width:"120px",
fixed: "right"
    },
  ];

  
  return (

    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Data Calon Santri PPDB Online
        </div>
       {/* <button className='bg-brand-600 text-white p-2 rounded'> Download Excel</button> */}
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
      <Table columns={columns} dataSource={data}   scroll={{
      x: 1500,
    
    }} 
    
    pagination={{
        ...pagination,
        total: data.length, // Total data yang tersedia
        onChange: handleChangePagination,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '20', '50'], // Pilihan ukuran halaman
        showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} dari ${total} data`,
      }}
    />
      </div>
    </Card>
  );
};

export default PPDBSantri;
