import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const FreeCard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Hapus token dari localStorage
    localStorage.removeItem('token');
  
    
    // Redirect ke halaman login
    navigate('/auth');

};

  return (
    <div className="relative mt-14 flex w-[256px] justify-center rounded-[20px] bg-gradient-to-br from-[#008000] via-[#008100] to-green-500 pb-4">
      <div className="absolute -top-12 flex h-24 w-24 items-center justify-center rounded-full border-[4px] border-white bg-gradient-to-b from-[#008000] to-green-900 dark:!border-navy-800">
    <img src="https://res.cloudinary.com/dyhunlqi0/image/upload/v1711777160/ni_c0xwq7.png" width="50px" height="60px" />
       
      </div>

      <div className="mt-16 flex h-fit flex-col items-center">
        <p className="text-lg font-bold text-white">Hallo Admin, </p>
        <p className="mt-1 px-4 text-center text-sm text-white">
         Selamat Bekerja
        </p>

        <label
          
          className="text-medium mt-7 cursor-poin block rounded-full bg-gradient-to-b from-white/50 to-white/10 py-[12px] px-11 text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 "
          onClick={handleLogout}
        >
          LOGOUT
        </label>
      </div>
    </div>
  );
};

export default FreeCard;
