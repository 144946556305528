import React, { useEffect, useState } from "react";
import axios from "../../../../axiosConfig.js";
import { Modal, notification, message, Spin, Avatar } from 'antd';
import { FiSearch } from "react-icons/fi";
import { FaPencilAlt, FaTrash, FaEye } from "react-icons/fa";
import { LoadingOutlined } from '@ant-design/icons';
import Card from "components/card";

const HistoryCard = () => {
  const [teacherData, setTeacherData] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    gender: "",
    position: "",
    formal_education: "",
    graduate_year: "",
    major: "",
    informal_education: ""
  });

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false); // State for delete loading
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [selectedEditId, setSelectedEditId] = useState(null);
  const [editing, setEditing] = useState(false); // State for edit loading
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/teacher/all');
        setTeacherData(response.data.data); // Pastikan respons dari API sesuai dengan yang Anda harapkan

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Ambil token dari local storage
      if (!token) {
        throw new Error('Token not found');
      }

      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
      if (selectedFile) {
        formDataToSend.append('image', selectedFile);
      }

      const response = await axios.post('/teacher', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      // Clear form fields and file selection after successful submission
      setFormData({
        name: "",
        address: "",
        gender: "",
        position: "",
        formal_education: "",
        graduate_year: "",
        major: "",
        informal_education: ""
      });
      setSelectedFile(null);

      // Handle API response
      if (response.status === 200) {
        const responseData = response.data.data;

        // Show success notification
        notification.success({
          message: 'Sukses',
          description: 'Data tenaga pendidik berhasil ditambahkan.'
        });

        // Close modal
        setModalAdd(false);
        window.location.reload()
      } else {
        throw new Error('Failed to add data');
      }
    } catch (error) {
      console.error('Error submitting data:', error);

      // Show error notification
      notification.error({
        message: 'Error',
        description: 'Gagal menambahkan data tenaga pendidik. Silakan coba lagi.'
      });
    } finally {
      setLoading(false); // Set loading state to false after submission (success or failure)
    }
  };

  const handleEdit = async (id) => {
    setSelectedEditId(id); // Set selectedEditId first
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      // Fetch data for selectedEditId
      const response = await axios.get(`/teacher/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const { data } = response.data;
        // Set formData state with fetched data
        setFormData({
          name: data.name,
          address: data.address,
          gender: data.gender,
          position: data.position,
          formal_education: data.formal_education,
          graduate_year: data.graduate_year,
          major: data.major,
          informal_education: data.informal_education
        });

        // Show edit modal
        setModalEdit(true);
      
      } else {
        throw new Error('Failed to fetch data for editing');
      }
    } catch (error) {
      console.error('Error fetching data for edit:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch data for editing. Please try again.'
      });
    }
  };


  const handleUpdate = async (e) => {
    e.preventDefault();
    setEditing(true);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const formDataToSend = new FormData();

      // Append existing form data fields
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });

      // Check if there's a new file selected
      if (selectedFile) {
        formDataToSend.append('image', selectedFile);
      }

      const response = await axios.put(`/teacher/${selectedEditId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      // Show success notification
      message.success('Data Berhasil Diedit');

      // Reset form and state after successful update
      setFormData(formData);  // Assuming initialFormData is defined correctly
      setSelectedFile(null);
      setEditing(false);
      setModalEdit(false); // Close modal or perform other actions as needed
      window.location.reload()

    } catch (error) {
      console.error('Error updating data:', error);
      // Show error notification
      message.error('Failed to update. Please try again.');

      // Handle error state or display error message to user
    } finally {
      // Turn off loading indicator
      setEditing(false);

    }
  };


  const handleDetailClick = (teacher) => {
    setSelectedDetail(teacher); // Set data tenaga pendidik yang dipilih ke dalam state
    setModalDetail(true); // Tampilkan modal detail
  };

  const handleDelete = async () => {
    setDeleting(true); // Start delete loading
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const response = await axios.delete(`/teacher/${selectedDeleteId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Teacher data deleted successfully.'
        });
        setModalDelete(false);
        // Update teacherData after deletion
        const updatedData = teacherData.filter(data => data.id !== selectedDeleteId);
        setTeacherData(updatedData);

        window.location.reload()
      } else {
        throw new Error('Failed to delete data');
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to delete teacher data. Please try again.'
      });
    } finally {
      setDeleting(false); // Stop delete loading
    }
  };

   // Handler untuk mengatur nilai pencarian
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Fungsi untuk melakukan filter berdasarkan nama atau nama + jabatan
  const filteredTeachers = teacherData.filter((teacher) => {
    // Lowercase untuk memudahkan pencarian tanpa memperhatikan case
    const fullName = `${teacher.name} ${teacher.position}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });





  return (
    <Card extra={"mt-3 !z-5 overflow-hidden"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          Tenaga Pendidik
        </div>
        <button className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20" onClick={() => setModalAdd(true)}>
          Tambah Data
        </button>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[300px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={searchTerm}
            onChange={handleSearchChange}
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
      </div>

      <div style={{ height: "40rem", overflow: "auto" }}>
      {filteredTeachers.map((data, index) => (
        <div key={index} className="flex w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          <div className="flex items-center gap-3">
            <div className="flex h-16 w-16 items-center justify-center">
              <img
                className="h-full w-full rounded-xl"
                src={data.image_url}
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-bold text-navy-700 dark:text-white">
                {data.name}
              </h5>
              <p className="mt-1 text-sm font-normal text-gray-600">
                {data.position}
              </p>
            </div>
          </div>

          <div className="mt-2 flex gap-4 items-center justify-center text-navy-700 dark:text-white">
            {/* Icon Edit */}
            <div className="underline cursor-pointer text-blue-700" title="Edit" onClick={() => handleEdit(data.id)}>
              <FaPencilAlt />
            </div>

            {/* Icon Hapus */}
            <div className="underline cursor-pointer text-red-600" title="Hapus" onClick={() => { setModalDelete(true); setSelectedDeleteId(data.id); }}>
              <FaTrash />
            </div>

            {/* Icon Lihat Detail */}
            <div className="underline cursor-pointer text-gray-500" title="Lihat Detail" onClick={() => handleDetailClick(data)}>
              <FaEye />
            </div>
          </div>
        </div>
      ))}
      </div>

      {/* MODAL ADD */}
      <Modal
        open={modalAdd}
        title="Tambah Tenaga Pendidik"
        onCancel={() => setModalAdd(false)}
        footer={null}
      >
        <div className='p-5'>
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label htmlFor="name" className="mb-3 block text-sm font-medium text-[#07074D]">
                Nama Lengkap
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label htmlFor="address" className="mb-3 block text-sm font-medium text-[#07074D]">
                Alamat
              </label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Enter your address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label htmlFor="gender" className="mb-3 block text-sm font-medium text-[#07074D]">
                    Jenis Kelamin
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="">Pilih Jenis Kelamin</option>
                    <option value="Laki - Laki">Laki- Laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </select>
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label htmlFor="position" className="mb-3 block text-sm font-medium text-[#07074D]">
                    Jabatan
                  </label>
                  <input
                    type="text"
                    name="position"
                    id="position"
                    value={formData.position}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
            </div>
            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="formal_education"
                    id="formal_education"
                    placeholder="Pendidikan Formal"
                    value={formData.formal_education}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="graduate_year"
                    id="graduate_year"
                    placeholder="Tahun Lulus"
                    value={formData.graduate_year}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="major"
                    id="major"
                    placeholder="Jurusan"
                    value={formData.major}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="informal_education"
                    id="informal_education"
                    placeholder="Pendidikan Informal"
                    value={formData.informal_education}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
              <div className="relative w-full">
                <div className="items-center justify-center max-w-xl mx-auto">
                  <label
                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                    id="drop"
                  >
                    <span className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <span className="font-medium text-gray-600">
                        Upload Foto Tenaga Pendidik
                        <span className="text-blue-600 underline ml-[4px]">from device</span>
                      </span>
                    </span>
                    <input
                      type="file"
                      name="image"
                      className="hidden"
                      accept="image/png,image/jpeg"
                      id="image"
                      onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                    />
                  </label>
                  {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                  {selectedFile && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {selectedFile.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#FFF' }} spin />} /> : 'Tambah Data'}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* // MODAL EDIT */}
      <Modal
        open={modalEdit}
        title="Edit Tenaga Pendidik"
        onCancel={() => setModalEdit(false)}
        footer={null}
      >
        <div className='p-5'>
          <form onSubmit={handleUpdate}>
            <div className="mb-5">
              <label htmlFor="name" className="mb-3 block text-sm font-medium text-[#07074D]">
                Nama Lengkap
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label htmlFor="address" className="mb-3 block text-sm font-medium text-[#07074D]">
                Alamat
              </label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Enter your phone number"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label htmlFor="gender" className="mb-3 block text-sm font-medium text-[#07074D]">
                    Jenis Kelamin
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="">Pilih Jenis Kelamin</option>
                    <option value="Laki - laki">Laki- Laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </select>
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label htmlFor="position" className="mb-3 block text-sm font-medium text-[#07074D]">
                    Jabatan
                  </label>
                  <input
                    type="text"
                    name="position"
                    id="position"
                    value={formData.position}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
            </div>
            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="formal_education"
                    id="formal_education"
                    placeholder="Pendidikan Formal"
                    value={formData.formal_education}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="graduate_year"
                    id="graduate_year"
                    placeholder="Tahun Lulus"
                    value={formData.graduate_year}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="major"
                    id="major"
                    placeholder="Jurusan"
                    value={formData.major}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <input
                    type="text"
                    name="informal_education"
                    id="informal_education"
                    placeholder="Pendidikan Informal"
                    value={formData.informal_education}
                    onChange={handleInputChange}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
              <div className="relative w-full">
                <div className="items-center justify-center max-w-xl mx-auto">
                  <label
                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                    id="drop"
                  >
                    <span className="flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <span className="font-medium text-gray-600">
                        Upload Foto Tenaga Pendidik
                        <span className="text-blue-600 underline ml-[4px]">from device</span>
                      </span>
                    </span>
                    <input
                      type="file"
                      name="image"
                      className="hidden"
                      accept="image/png,image/jpeg"
                      id="image"
                      onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                    />
                  </label>
                  {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                  {selectedFile && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {selectedFile.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {editing ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#FFF' }} spin />} /> : 'Simpan Perubahan'}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* MODAL DETAIL */}
      
      <Modal
        visible={modalDetail}
        title="Detail Tenaga Pendidik"
        onCancel={() => setModalDetail(false)}
        footer={null}
      >
        {selectedDetail && (
          <div className='p-5'>
            <div className="mb-5 flex items-center">
              <div className="flex h-56 w-52 items-center justify-center">
                <Avatar
                  className="h-full w-full rounded-xl"
                  src={selectedDetail.image_url}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <label htmlFor="name" className="block text-sm font-medium text-[#07074D]">
                  Nama Lengkap
                </label>
                <label>{selectedDetail.name}</label>
              </div>
            </div>

            <div className="flex items-start justify-between mb-5">
              <div className="w-1/2 pr-4">
                <label htmlFor="position" className="block text-sm font-medium text-[#07074D]">
                  Jabatan
                </label>
                <label>{selectedDetail.position}</label>
              </div>
              <div className="w-1/2 pl-4">
                <label htmlFor="gender" className="block text-sm font-medium text-[#07074D]">
                  Jenis Kelamin
                </label>
                <label>{selectedDetail.gender}</label>
              </div>
            </div>

            <div className="flex items-start justify-between mb-5">
              <div className="w-1/2 pr-4">
                <label htmlFor="formal_education" className="block text-sm font-medium text-[#07074D]">
                  Pendidikan Formal
                </label>
                <label>{selectedDetail.formal_education}</label>
              </div>
              <div className="w-1/2 pl-4">
                <label htmlFor="major" className="block text-sm font-medium text-[#07074D]">
                  Jurusan
                </label>
                <label>{selectedDetail.major}</label>
              </div>
            </div>

            <div className="flex items-start justify-between mb-5">
              <div className="w-1/2 pr-4">
                <label htmlFor="graduate_year" className="block text-sm font-medium text-[#07074D]">
                  Tahun Lulus
                </label>
                <label>{selectedDetail.graduate_year}</label>
              </div>
              <div className="w-1/2 pl-4">
                <label htmlFor="informal_education" className="block text-sm font-medium text-[#07074D]">
                  Pendidikan Informal
                </label>
                <label>{selectedDetail.informal_education}</label>
              </div>
            </div>

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-[#07074D]">
                Alamat
              </label>
              <label>{selectedDetail.address}</label>
            </div>
          </div>
        )}
      </Modal>


      {/* MODAL DELETE CONFIRMATION */}
      <Modal
        open={modalDelete}
        title="Konfirmasi Hapus"
        onOk={handleDelete} // Call handleDelete directly
        onCancel={() => setModalDelete(false)}
        footer={[]}
      >
        <div className="mx-auto bg-white">
          <p>Apakah anda yakin ingin menghapus data ini?</p>
          <div className="flex items-center gap-4 mt-4">
            <button className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none" onClick={handleDelete}>
              {deleting ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />} /> : "Ya"}
            </button>

            <button className="hover:shadow-form w-full rounded-md bg-red-600 text-white py-3 px-8 text-center text-sm font-semibold text-white outline-none" onClick={() => setModalDelete(false)}>
              Batal
            </button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default HistoryCard;
