import React, { useState, useEffect } from "react";
import { MdModeEditOutline } from "react-icons/md";
import axios from 'axios';
import { Modal, Image, DatePicker, message, Button } from 'antd';
import moment from 'moment';
import { format } from 'date-fns';
import Card from "components/card";
import { FaTrash } from "react-icons/fa";

const Project = () => {
  const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null); 
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    location: '',
    date: null,
    image: null
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const apiUrl = "https://api-ppmtqni.mku-dev.xyz/api/achievement";

  useEffect(() => {
    fetchAchievements();
  }, []);

  const fetchAchievements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUrl);
      const formattedAchievements = response.data.data.map(item => ({
        ...item,
        formattedDate: format(new Date(item.date), 'dd-MM-yyyy')
      }));
      setAchievements(formattedAchievements);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching achievements:", error);
      setLoading(false);
    }
  };

  const openModal = (achievement) => {
    setSelectedAchievement(achievement);
    setShowModal(true); // Set showModal to true to open modal
    setFormData({
      title: achievement.title,
      description: achievement.description,
      location: achievement.location,
      date: moment(achievement.date), // Assuming you are using moment.js for date management
      image: null // Assuming you don't want to change the image in details view
    });
  };
  

  const closeModal = () => {
    setShowModal(false);
    setShowEditModal(false)
  };

  const openEditModal = (achievement) => {
    setSelectedAchievement(achievement);
    setShowEditModal(true);
    setFormData({
      title: achievement.title,
      description: achievement.description,
      location: achievement.location,
      date: moment(achievement.date), // Assuming you are using moment.js for date management
      image: null // Assuming you don't want to change the image in edit mode
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const formDataWithImage = new FormData();
      formDataWithImage.append('title', formData.title);
      formDataWithImage.append('description', formData.description);
      formDataWithImage.append('location', formData.location);
      formDataWithImage.append('date', formData.date.toISOString()); // Ensure date is in ISO format
      formDataWithImage.append('image', selectedFile);

      const response = await axios.post(apiUrl, formDataWithImage, config);

      message.success('Data prestasi berhasil ditambahkan');
      setLoading(false);
      setShowModalAdd(false);
      fetchAchievements(); // Refresh achievements list
    } catch (error) {
      console.error('Error adding achievement:', error);
      message.error('Gagal menambahkan data prestasi');
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const formDataWithImage = new FormData();
      formDataWithImage.append('title', formData.title);
      formDataWithImage.append('description', formData.description);
      formDataWithImage.append('location', formData.location);
      formDataWithImage.append('date', formData.date.toISOString()); // Ensure date is in ISO format
      if (selectedFile) {
        formDataWithImage.append('image', selectedFile);
      }

      const editUrl = `${apiUrl}/${selectedAchievement.id}`;
      const response = await axios.put(editUrl, formDataWithImage, config);

      message.success('Data prestasi berhasil diperbarui');
      setLoading(false);
      setShowModal(false);
      fetchAchievements(); // Refresh achievements list
    } catch (error) {
      console.error('Error updating achievement:', error);
      message.error('Gagal memperbarui data prestasi');
      setLoading(false);
    }
  };

   // Fungsi untuk membuka modal konfirmasi hapus
   const confirmDelete = (item) => {
    setDeleteItem(item); // Set item yang akan dihapus ke dalam state
    Modal.confirm({
      title: 'Konfirmasi Hapus Prestasi',
      content: `Apakah Anda yakin ingin menghapus prestasi '${item.title}'?`,
      okText: 'Hapus',
      okType: 'danger',
      cancelText: 'Batal',
      onOk: () => handleDelete(item.id), // Panggil fungsi handleDelete jika user menekan tombol 'Hapus'
    });
  };

  const handleDelete = async (itemId) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
  
      // Make DELETE request with authorization header
      const response = await axios.delete(`https://api-ppmtqni.mku-dev.xyz/api/achievement/${itemId}`, config);
  
      if (response.status === 200) {
        message.success('Prestasi berhasil dihapus');
        fetchAchievements(); // Refresh achievements list after successful delete
      } else {
        throw new Error('Gagal menghapus prestasi');
      }
    } catch (error) {
      console.error('Error deleting achievement:', error);
      message.error(`Error: ${error.message}`);
    }
  };
  


  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Prestasi
        </h4>
        <Button type="primary" onClick={() => setShowModalAdd(true)} 
            className="linear flex items-center justify-center rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-900 active:bg-brand-900 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
          Tambah
        </Button>
        </div>
      
        <p className="mt-2 text-base text-gray-600">
          Daftar prestasi santri yang terpasang di website PPMTQNI Brebes, silahkan melakukan aksi untuk data ini (melihat, mengedit, menghapus, dan menambahkan).
        </p>
      </div>

      {/* Render list of achievements */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        achievements.map((item, index) => (
          <div key={index} className={`mt-${index === 0 ? '0' : '3'} flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none`}>
            <div className="flex items-center">
              <div className="">
                <img className="h-[83px] w-[83px] rounded-lg" src={item.image_url} alt="" />
              </div>
              <div className="ml-4">
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {item.title}
                </p>
                <p className="mt-2 text-sm text-gray-600">
                  {item.location} .
                  <a
                    className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white cursor-pointer"
                    onClick={() => openModal(item)}
                  >
                    See details
                  </a>
                </p>
              </div>
            </div>
            <div className="mr-4 flex items-center justify-center text-gray-600 gap-3 dark:text-white">
             
              <MdModeEditOutline onClick={() => openEditModal(item)}  title="Edit Prestasi" className="cursor-pointer" />
              <div
              className="text-red-500 text-md w-100 flex my-2 cursor-pointer"
              title="Hapus Prestasi"
              // onClick={() => showDeleteConfirm(image.id)}
            >
              <div
                className="text-red-500 text-md w-100 flex my-2 cursor-pointer"
                title="Hapus Prestasi"
                onClick={() => confirmDelete(item)} // Panggil fungsi untuk membuka modal konfirmasi hapus
              >
                <FaTrash />
              </div>
            </div>
            </div>
          </div>
        ))
      )}

      {/* Modal component using Ant Design Modal */}
      <Modal
        title={selectedAchievement?.title}
        open={showModal}
        onCancel={closeModal}
        footer={null}
      >
        <div className="text-base text-gray-600 mb-2">{selectedAchievement?.description}</div>
        <div className="text-sm text-gray-500 mb-2">{selectedAchievement?.location}</div>
        <div className="text-sm text-gray-500 mb-2">{selectedAchievement ? format(new Date(selectedAchievement.date), 'dd-MM-yyyy') : ''}</div>
        <Image
          src={selectedAchievement?.image_url}
          alt={selectedAchievement?.title}
          preview={true}
          width={400}
        />
      </Modal>

      {/* Modal for adding new achievement */}
      <Modal
        open={showModalAdd}
        title="Tambah Prestasi"
        onCancel={() => setShowModalAdd(false)}
        footer={null}
      >
        <div className='p-5'>
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label htmlFor="title" className="mb-3 block text-sm font-medium text-[#07074D]">
                Judul
              </label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Judul"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-sm font-medium text-[#07074D]">
                Deskripsi
              </label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder="Enter Deskripsi"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="location" className="mb-3 block text-sm font-medium text-[#07074D]">
                Lokasi
              </label>
              <input
                type="text"
                name="location"
                id="location"
                placeholder="Enter Lokasi"
                value={formData.location}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="date" className="mb-3 block text-sm font-medium text-[#07074D]">
                Waktu
              </label>
              <DatePicker
                name="date"
                id="date"
                value={formData.date} // Value should be moment object
                onChange={(date, dateString) => setFormData({ ...formData, date })}
                className="w-full"
              />
            </div>

            <div className="relative w-full">
              <div className="items-center justify-center max-w-xl mx-auto">
                <label
                  className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                >
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Upload Foto Prestasi
                      <span className="text-blue-600 underline ml-[4px]">from device</span>
                    </span>
                  </span>
                  <input
                    type="file"
                    name="image"
                    className="hidden"
                    accept="image/png,image/jpeg"
                    id="image"
                    onChange={handleFileSelect}
                  />
                </label>
                {selectedFile && (
                  <p className="mt-2 text-sm text-gray-600">
                    Selected file: {selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
              >
                {loading ? 'Loading...' : 'Tambah Data'}
              </button>
            </div>
          </form>
        </div>
      </Modal>


      {/* Modal component using Ant Design Modal */}
      <Modal
        title="Edit Prestasi"
        open={showEditModal}
        onCancel={closeModal}
        footer={null}
      >
        <div className='p-5'>
          <form onSubmit={handleEditSubmit}>
            <div className="mb-5">
              <label htmlFor="title" className="mb-3 block text-sm font-medium text-[#07074D]">
                Judul
              </label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Judul"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-sm font-medium text-[#07074D]">
                Deskripsi
              </label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder="Enter Deskripsi"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="location" className="mb-3 block text-sm font-medium text-[#07074D]">
                Lokasi
              </label>
              <input
                type="text"
                name="location"
                id="location"
                placeholder="Enter Lokasi"
                value={formData.location}
                onChange={handleInputChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="date" className="mb-3 block text-sm font-medium text-[#07074D]">
                Waktu
              </label>
              <DatePicker
                name="date"
                id="date"
                value={formData.date} // Value should be moment object
                onChange={(date, dateString) => setFormData({ ...formData, date })}
                className="w-full"
              />
            </div>

            <div className="relative w-full">
              <div className="items-center justify-center max-w-xl mx-auto">
                <label
                  className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                >
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Upload Foto Prestasi
                      <span className="text-blue-600 underline ml-[4px]">from device</span>
                    </span>
                  </span>
                  <input
                    type="file"
                    name="image"
                    className="hidden"
                    accept="image/png,image/jpeg"
                    id="image"
                    onChange={handleFileSelect}
                  />
                </label>
                {selectedFile && (
                  <p className="mt-2 text-sm text-gray-600">
                    Selected file: {selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-8 flex justify-end">
              <Button type="primary" htmlType="submit" loading={loading} className="bg-brand-500">
                Simpan
              </Button>
            </div>
          </form>
        </div>
      </Modal>

    </Card>
  );
};

export default Project;
