import React, { useEffect, useState } from 'react';
import { Button, Modal, notification, Upload, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';

const Banner1 = () => {
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // state untuk menyimpan file yang dipilih
  const [videoUrl, setVideoUrl] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [uploadProgress, setUploadProgress] = useState(0);

  const axiosInstance = axios.create({
    baseURL: 'https://api-ppmtqni.mku-dev.xyz/api',
    // You can add headers or other configurations here if needed
  });

  const fetchVideoUrl = async () => {
    try {
      const response = await axiosInstance.get('/video/latest');
      if (response.data && response.data.data && response.data.data.video_url) {
        setVideoUrl(response.data.data.video_url);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error fetching video:', error);
      // Handle error or display fallback video
    }
  };

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      notification.error({
        message: 'Error',
        description: 'Silakan pilih video untuk diunggah.',
      });
      return;
    }

    const formData = new FormData();
    formData.append('video', selectedFile);

    try {
      setUploading(true);
      const response = await axiosInstance.post('/video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      setUploading(false);
      setUploadProgress(0); // Reset progress setelah selesai upload
      notification.success({
        message: 'Success',
        description: 'Video berhasil diunggah.',
      });

      // Lakukan sesuatu setelah upload sukses jika perlu
      // Misalnya: fetch ulang daftar video terbaru
      fetchVideoUrl();

      // Tutup modal setelah upload sukses
      setOpen(false);
    } catch (error) {
      setUploading(false);
      setUploadProgress(0); // Reset progress setelah upload gagal
      console.error('Error uploading video:', error);
      notification.error({
        message: 'Error',
        description: 'Gagal mengunggah video. Silakan coba lagi.',
      });
    }
  };

  return (
    <div className="flex w-full flex-col rounded-[20px] bg-blue-700 p-0">
      <div className="w-full relative">
        {videoUrl && (
          <video autoPlay loop muted className="rounded-[20px]">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

<div className="absolute top-10 left-20 bg-blue-500 bg-opacity-50 rounded-[10px] p-4">
          <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
            Video Background Website
          </h4>
          <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
            Ubah video background website dengan klik tombol dibawah (tidak diizinkan mengosongkan video)
          </p>

          <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
            <button className="text-white linear rounded-xl bg-blue-900 px-4 py-2 text-center text-base font-medium transition duration-200 hover:bg-blue-700 active:bg-blue-500" onClick={showModal}>
              Ubah Video
            </button>
          </div>
        </div>
      </div>

      {contextHolder}

      <Modal
        open={open}
        title="Ubah Background Video"
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={uploading}
            onClick={handleSubmit}
            className="p-5 bg-blue-600"
          >
            Submit
          </Button>,
        ]}
      >
        <div className="p-5">
          <div className="max-w-xl mx-auto">
            <label
              className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
              id="drop"
            >
              <span className="flex items-center space-x-2">
                <InboxOutlined className="text-gray-600 text-6xl" />
                <span className="font-medium text-gray-600">
                  Upload Video Background (max 15 detik)
                  <span className="text-blue-600 underline ml-1">from device</span>
                </span>
              </span>
              <input
                type="file"
                name="video"
                className="hidden"
                accept="video/mp4"
                onChange={handleFileSelect}
              />
            </label>
            {selectedFile && (
              <p className="mt-2 text-sm text-gray-600">
                Selected file: {selectedFile.name}
              </p>
            )}

            {uploading && (
              <div className="mt-4 mb-4">
                <Progress percent={uploadProgress} status={uploadProgress === 100 ? 'success' : 'active'} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Banner1;
